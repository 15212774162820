import { observable } from 'mobx';

const authStore = observable({
    API_KEY: "",
    SIGN_UP: true,

    USER_ID: "",
    tokenExpired: false,
    language: "en",

    USER_INFO: "",
});

export { authStore };