import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
	Box,
	Button,
	Checkbox,
	Container,
	FormHelperText,
	TextField,
	Typography, MenuItem,
	makeStyles, Select, FormControl, InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Page from 'src/components/Page';
import axios from 'axios';
import useStore from '../../useStore';
import { useTranslation } from 'react-i18next';

const { authStore, apiStore } = useStore();

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		height: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3)
	},
	formControl: {
		marginTop: '20px',
		minWidth: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const StyledButton = withStyles({
	root: {
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		borderRadius: 3,
		border: 0,
		color: 'white',
		height: 48,
		padding: '0 30px',
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	},
	label: {
		textTransform: 'capitalize',
	},
})(Button);


const RegisterView = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const { TRANSLATE_KR_STORE, TRANSLATE_EN_STORE } = useStore();
	const [lang, setLang] = useState();
	var pre_lang;
	const { t } = useTranslation();

	const [visible, setVisible] = useState(false);

	const creatorGender = [
		{
			value: 0,
			label: t('ci.female')
		},
		{
			value: 1,
			label: t('ci.male')
		}
	]

	async function registerAdoba(value) {
		const data = new FormData()

		data.append("account", value.account)
		data.append("group_id", value.group_id)
		data.append("password", value.password)
		data.append("name", value.name)
		data.append("sex", value.sex)

		let url = apiStore.API_REGISTER;
		console.log(url);
		await axios.post(url, data, {
			headers: {
				"Content-Type": "application/form-data; charset=UTF-8"
			}
		}).then((res) => {
			console.log("가입 결과: ", res);
		});
	}

	useEffect(() => {
		loadLanguage();
		getGroup();
	}, []);

	async function loadLanguage() {
		if (localStorage.lang === "kr") {
			pre_lang = TRANSLATE_KR_STORE;
		} else {
			pre_lang = TRANSLATE_EN_STORE;
		}
		await setLang(pre_lang);
		await setLoading(false)
	}

	const [groupList, setGroupList] = useState([])
	async function getGroup() {
		await axios.get(apiStore.API_GET_RESOURCE, {
			params: { key: "group" },
			headers : {
                "token": authStore.API_KEY,
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
		}).then(res => {
			console.log(res)
			setGroupList(res.data.data.user_group)
		}).catch(err => {
			console.log(err)
		})
	}

	return (
		<Page className={classes.root} title="Register">
			<h2>NOT SUPPORT</h2>
		</Page>
	)


	if(visible) { return (
		<Page
			className={classes.root}
			title="Register"
		>
			{loading ?
				null
				:
				<Box
					display="flex"
					flexDirection="column"
					height="100%"
					justifyContent="center"
				>
					<Container maxWidth="sm">
						<Formik
							initialValues={{
								name: '',
								group_id: '',
								account: '',
								password: '',
								sex: '',
								register_code: '',
								policy: false
							}}
							validationSchema={
								Yup.object().shape({
									account: Yup.string().email(t('regi.email_is_not_right')).max(255).required(t('regi.email_is_not_right')),
									name: Yup.string().max(8).required(t('please_write_name')),
									password: Yup.string().max(16).required(t('please_write_password')),
									sex: Yup.string().max(16).required(t('please_write_sex')),
									register_code: Yup.string().max(255).required(t('enter_the_register_code')),
									policy: Yup.boolean().oneOf([true], t('must_agree'))
								})
							}
							onSubmit={(value) => {
								// navigate('/app/dashboard', { replace: true });
								if (value.register_code != 'ADOBAXCXC') {
									alert(t('register_code_is_wrong'));
									window.location.reload();
								} else {
									registerAdoba(value);
									authStore.SIGN_UP = true;
									navigate('/login');
									alert(t('admin_register_done'));
								}
							}}
						>
							{({
								errors,
								handleBlur,
								handleChange,
								handleSubmit,
								isSubmitting,
								touched,
								values
							}) => (
								<form onSubmit={handleSubmit}>
									<Box mb={3}>
										<Typography
											color="textPrimary"
											variant="h2"
										>
											{t('regi.register_admin_account')} 💁💁‍♂
										</Typography>
										<Typography
											color="textSecondary"
											gutterBottom
											variant="body2"
											style={{ marginTop: '10px' }}
										>
											{t('regi.register_admin_adoba_account')}
										</Typography>
									</Box>
									<TextField
										error={Boolean(touched.name && errors.name)}
										fullWidth
										helperText={touched.name && errors.name}
										label={t('regi.register_name')}
										margin="normal"
										name="name"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.name}
										variant="outlined"
									/>
									<FormControl className={classes.formControl}>
										<TextField
											fullWidth
											label={t('regi.choose_your_team')}
											name="group_id"
											onBlur={handleBlur}
											helperText={t('regi.choose_adoba_or_adoba_ai_team')}
											onChange={handleChange}
											required
											select
											value={values.group_id}
											variant="outlined"
										>
											<MenuItem value="" disabled></MenuItem>
											{
												groupList.map(el => {
													// return <MenuItem value={el.id}>{t(`regi.${el.group_name}`)}</MenuItem>
													return <MenuItem value={el.id}>{el.group_name}</MenuItem>
												})
											}
											{/* <MenuItem value={8}>{t('regi.head_team')}</MenuItem>
											<MenuItem value={1}>{t('regi.cp_1team')}</MenuItem>
											<MenuItem value={2}>{t('regi.cp_2team')}</MenuItem>
											<MenuItem value={3}>{t('regi.cp_3team')}</MenuItem>
											<MenuItem value={4}>{t('regi.contens_support')}</MenuItem>
											<MenuItem value={5}>{t('regi.contact_team')}</MenuItem>
											<MenuItem value={6}>{t('regi.business_team')}</MenuItem>
											<MenuItem value={7}>{t('regi.developer_team')}</MenuItem>
											<MenuItem value={9}>{t('regi.pp_1team')}</MenuItem> */}
										</TextField>
									</FormControl>
									<TextField
										error={Boolean(touched.account && errors.account)}
										fullWidth
										helperText={touched.account && errors.account}
										label={t('regi.email')}
										margin="normal"
										name="account"
										onBlur={handleBlur}
										onChange={handleChange}
										type="email"
										value={values.account}
										variant="outlined"
									/>
									<TextField
										fullWidth
										label={t('add.gender')}
										name="sex"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										select
										value={values.sex}
										variant="outlined"
									>
										<MenuItem key="" value="" disabled></MenuItem>
										{
											creatorGender.map(option => {
												return <MenuItem value={option.value}>{option.label}</MenuItem>
											})
										}
									</TextField>
									<TextField
										error={Boolean(touched.password && errors.password)}
										fullWidth
										helperText={touched.password && errors.password}
										label="password"
										margin="normal"
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										type="password"
										value={values.password}
										variant="outlined"
									/>
									<TextField
										error={Boolean(touched.register_code && errors.register_code)}
										fullWidth
										helperText={touched.register_code && errors.register_code}
										label={t('regi.register_code')}
										margin="normal"
										name="register_code"
										onBlur={handleBlur}
										onChange={handleChange}
										type="password"
										value={values.register_code}
										variant="outlined"
									/>
									<FormHelperText>{t('regi.register_code_is_all_capital_letter')}</FormHelperText>
									<Box
										alignItems="center"
										display="flex"
										ml={-1}
									>
										<Checkbox
											checked={values.policy}
											name="policy"
											onChange={handleChange}
										/>
										<Typography
											color="textSecondary"
											variant="body1"
										>
											{t('regi.you_agree_this')}
											{' '}
											{/* <Link
											color="primary"
											component={RouterLink}
											to="#"
											underline="always"
											variant="h6"
											> */}
											{/* {t('regi.policy')} */}
											{/* </Link> */}
										</Typography>
									</Box>
									{Boolean(touched.policy && errors.policy) && (
										<FormHelperText error>
											{errors.policy}
										</FormHelperText>
									)}
									<Box my={2}>
										<StyledButton
											style={{ backgroundColor: '#fa243c', color: '#fff' }}
											disabled={isSubmitting}
											fullWidth
											size="large"
											type="submit"
											variant="contained"
										>
											{t('regi.sign_up')}
										</StyledButton>
									</Box>
									<Box
										color="textSecondary"
										variant="body1"
									>
										{t('regi.already_have_account')}
										{' '}
										<Box
											style={{ color: '#ff3300', cursor: 'pointer' }}
											onClick={() => { authStore.SIGN_UP = true; navigate('/login') }}
										>
											{t('login')}
										</Box>
									</Box>
								</form>
							)}
						</Formik>

					</Container>
				</Box>
			}
		</Page>
	);
	}
};

export default RegisterView;
