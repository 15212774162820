import { test1 } from './test/test1';
import { creatorStore } from './stores/creatorStore';
import { authStore } from './stores/authStore';
import { apiStore } from './stores/apiStore';
import { PLATFORM_STORE } from './stores/platformStore';
import { VIDEO_STORE } from './stores/videoStore';
import { COMPARE_STORE } from './stores/compareStore';
import { UPLOAD_STORE } from './stores/uploadStore';
import { DATA_ANALYSIS } from './stores/dataAnalysisStore';
import { DATA_DASHBOARD } from './stores/dataDashboard';
import { ADOBA_REPORT } from './stores/adobaReport';

// translate
import { TRANSLATE_KR_STORE } from './stores/languages/TRANSLATE_KR_STORE';
import { TRANSLATE_EN_STORE } from './stores/languages/TRANSLATE_EN_STORE';

const useStore = () => {
    return {
        test1,
        creatorStore,
        authStore,
        apiStore,
        PLATFORM_STORE,
        VIDEO_STORE,
        COMPARE_STORE,
        UPLOAD_STORE,
        DATA_ANALYSIS,
        DATA_DASHBOARD,
        ADOBA_REPORT,

        TRANSLATE_KR_STORE,
        TRANSLATE_EN_STORE,
    };
};

export default useStore;