import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
import translationEN from './locales/en/translation';
import translationKR from './locales/kr/translation';
import translationCH from './locales/ch/translation';
import translationJP from './locales/jp/translation';

const resource = {
  en: {
    translation: translationEN
  },
  kr: {
    translation: translationKR
  },
  ch: {
    translation: translationCH
  },
  jp: {
    translation: translationJP
  },
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: resource,
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    keySeparator: false, // we do not use keys in form 
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

 

export default i18n;