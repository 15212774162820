import { observable } from 'mobx';

const DATA_ANALYSIS = observable({
    YESTODAY_DATA: "",
    select_start_date: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)),
    select_end_date: new Date(),
    platform_id: "",
    data_type: ""
})

export { DATA_ANALYSIS }