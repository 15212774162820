import { observable } from 'mobx';

const VIDEO_STORE = observable({
    MAX_VIEWS: [],
    MAX_SCORES: [],
    DELETED_VIDEOS: [],
    TOTAL_CREATORS: "",
    TOTAL_PLATFORMS: "",
    TOTAL_VIDEOS: "",
    TOTAL_CHANNELS: "",

    VIDEO_DATA: "",
    VIDEO_DATA2: "",

    VIDEO_DETAIL_ID: "",
    VIDEO_INFO: []
});

export {VIDEO_STORE};