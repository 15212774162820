import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

const DashboardLayout = lazy(() => import('src/layouts/DashboardLayout'))
const MainLayout = lazy(() => import('src/layouts/MainLayout'))
const AccountView = lazy(() => import('src/views/account/AccountView'))
const CustomerListView = lazy(() => import('src/views/customer/CustomerListView'))
const DashboardView = lazy(() => import('src/views/reports/DashboardView'))
const LoginView = lazy(() => import('src/views/auth/LoginView'))
const NotFoundView = lazy(() => import('src/views/errors/NotFoundView'))
const ProductListView = lazy(() => import('src/views/product/ProductListView'))
const RegisterView = lazy(() => import('src/views/auth/RegisterView'))
const SettingsView = lazy(() => import('src/views/settings/SettingsView'))
const Add_Creator = lazy(() => import('src/views/add_creator/add_creator'))
const Modify_Creator = lazy(() => import('src/views/add_creator/modify_creator'))
const Creator_info = lazy(() => import('src/views/creator/creator_info/creator_info'))
const PrivateLetter = lazy(() => import('src/views/creator/private_letter/private_letter'))
const CreatorVideoList = lazy(() => import('src/views/creator/creatorVideoList/creatorVideoList'))
const CreatorCompare = lazy(() => import('src/views/creator/creator_compare/creator_compare'))
const AlertDashboard = lazy(() => import('src/views/alert_dashboard/alert_dashboard'))
const TotalComparePanel = lazy(() => import('src/views/total_compare/total_compare_panel'))

const BrPage = lazy(() => import('src/views/br_page/index'))
const Egg = lazy(() => import('src/views/log/egg/egg'))
const Test2 = lazy(() => import('src/test/test2'))

const PlatformBili = lazy(() => import('src/views/platform/platform_bili'))
const PlatformHaokan = lazy(() => import('src/views/platform/platform_haokan'))
const PlatformIqiyi = lazy(() => import('src/views/platform/platform_iqiyi'))
const PlatformKwai = lazy(() => import('src/views/platform/platform_kwai'))
const PlatformTencent = lazy(() => import('src/views/platform/platform_tencent'))
const PlatformTiktok = lazy(() => import('src/views/platform/platform_tiktok'))
const PlatformWang = lazy(() => import('src/views/platform/platform_wang'))
const PlatformWeibo = lazy(() => import('src/views/platform/platform_weibo'))
const PlatformXigua = lazy(() => import('src/views/platform/platform_xigua'))
const PlatformYang = lazy(() => import('src/views/platform/platform_yang'))

const VideoDashboard = lazy(() => import('src/views/video_dashboard/index'))
const VideoDetailSection = lazy(() => import('src/views/videoDetailSection/videoDetailSection'))
const VideoCompare = lazy(() => import('src/views/videoDetailSection/videoCompare'))

const Board = lazy(() => import('src/views/board/index'))
const CreatorAdjustment = lazy(() => import('src/views/creatorAdjustment/creator_adjustment'))

const AdobaReport = lazy(() => import('src/views/adoba_report/adoba_report'))
const ReportPage = lazy(() => import('src/views/adoba_report/report'))
const AdobaLabs = lazy(() => import('src/views/adoba_labs/adoba_labs'))

const UploadMain = lazy(() => import('src/views/upload/upload_main/uploadMain'))
const UploadStation = lazy(() => import('src/views/upload/upload_detail/upload_station'))
const MarketDashboard = lazy(() => import('src/views/marketstore/market_dashboard'))

const BiliDoc = lazy(() => import('src/views/creator/documents/bili_doc'));
const BiliDoc_HH = lazy(() => import('src/views/creator/documents/bili_doc_hh'));
const AuthorizationDoc = lazy(() => import('src/views/creator/documents/authorization_doc'));
const XiguaRightDoc = lazy(() => import('src/views/creator/documents/xigua_right_doc'));
const XiguaSignupDoc = lazy(() => import('src/views/creator/documents/xigua_signup_doc'));

const DataDashboard = lazy(() => import('src/views/data_dashboard/index'))
const CreatorTracking = lazy(() => import('src/views/creator_tracking/index'))

const AdobaStudioLog = lazy(() => import('src/views/log/log'))
const DataAnalysis = lazy(() => import('./views/creator/data_analysis/data_analysis'))
const DataReport = lazy(() => import('./views/creator/data_report/data_report'))
const ModifyAccount = lazy(() => import('./views/add_creator/modify/modify_account'))
const OutsideCreator = lazy(() => import('src/views/outside_data/outside_creator'))
const OutsideVideo = lazy(() => import('src/views/outside_data/outside_video'))
const ConnectCrData = lazy(() => import('src/views/outside_data/connect_creator_data'))
const ConnectVideoData = lazy(() => import('src/views/outside_data/connect_video_data'))
const ConnectTaskData = lazy(() => import('src/views/outside_data/connect_task_data'))


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'creator_info', element: <Creator_info /> },
      { path: 'private_letter', element: <PrivateLetter /> },
      { path: 'creator_video_list', element: <CreatorVideoList /> },
      { path: 'add_creator', element: <Add_Creator /> },
      { path: "modify_creator", element: <Modify_Creator /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'data_analysis', element: <DataAnalysis /> },
      { path: 'modify_account', element: <ModifyAccount /> },
      { path: 'data_report', element: <DataReport /> },

      { path: 'total_compare', element: <TotalComparePanel /> },
      { path: 'data_dashboard', element: <DataDashboard /> },
      { path: 'creator_tracking', element: <CreatorTracking /> },

      { path: 'platform_bili', element: <PlatformBili /> },
      { path: 'platform_haokan', element: <PlatformHaokan /> },
      { path: 'platform_iqiyi', element: <PlatformIqiyi /> },
      { path: 'platform_kwai', element: <PlatformKwai /> },
      { path: 'platform_tencent', element: <PlatformTencent /> },
      { path: 'platform_tiktok', element: <PlatformTiktok /> },
      { path: 'platform_wang', element: <PlatformWang /> },
      { path: 'platform_weibo', element: <PlatformWeibo /> },
      { path: 'platform_xigua', element: <PlatformXigua /> },
      { path: 'platform_yang', element: <PlatformYang /> },

      { path: 'video_dashboard', element: <VideoDashboard /> },
      { path: 'video_detailSection', element: <VideoDetailSection /> },
      { path: 'video_compare', element: <VideoCompare /> },
      { path: 'creator_compare', element: <CreatorCompare /> },

      { path: 'board', element: <Board /> },
      { path: 'creator_adjustment', element: <CreatorAdjustment /> },

      { path: 'adoba_report', element: <AdobaReport /> },
      { path: 'report_page', element: <ReportPage /> },
      { path: 'adoba_labs', element: <AdobaLabs /> },

      { path: 'upload_main', element: <UploadMain /> },
      { path: 'upload_main/upload_station', element: <UploadStation /> },

      { path: 'market_dashboard', element: <MarketDashboard /> },
      { path: 'alert_dashboard', element: <AlertDashboard /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'test2', element: <Test2 /> },
      { path: '*', element: <Navigate to="/404" /> },

      { path: 'adoba_studio_log', element: <AdobaStudioLog /> },
      { path: 'outside_creator', element: <OutsideCreator /> },
      { path: 'outside_video', element: <OutsideVideo /> },
      { path: 'connect_cr_data', element: <ConnectCrData /> },
      { path: 'connect_video_data', element: <ConnectVideoData /> },
      { path: 'connect_task_data', element: <ConnectTaskData /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },

      { path: 'br_page', element: <BrPage /> },
      { path: 'bilidoc', element: <BiliDoc /> },
      { path: 'bilidoc_hh', element: <BiliDoc_HH /> },
      { path: 'authorization_doc', element: <AuthorizationDoc /> },
      { path: 'xiguaright_doc', element: <XiguaRightDoc /> },
      { path: 'xiguasignup_doc', element: <XiguaSignupDoc /> },
      { path: 'egg', element: <Egg /> },

      { path: '/', element: <Navigate to="/app/data_dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ]
  }
];

export default routes;
