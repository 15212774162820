import { observable } from 'mobx';

const creatorStore = observable({
    creator_id: 0,
    adoba_id: 0,
    FANS: "",
    LIKES: "",
    VIEWS: "",
    COMMENTS: "",

    FANS_DIFF: "",
    LIKES_DIFF: "",
    VIEWS_DIFF: "",
    COMMENTS_DIFF: "",

    // main Dashboard
    DASHBOARD_OVERVIEW: "",
    DASHBOARD_RANKING: "",

    // creator Video List Page
    CREATOR_INFO: "",

    // data
    DATA: "",
    DETAIL_DATA: "",

    // changing Team
    SET_THE_TEAM: "",

    // document Info
    DOCUMENT: "",
    UID: "",

    // creator's Memo
    MEMO_LIST: "",

    // chart : Platform Doughnut and Bar Chart Data
    CHART_DOUGHNUT: "",
    CHART_BAR: "",

    // chart: Monthly Platform Data
    CHART_MONTH_FANS: "",
    CHART_MONTH_VIEW: "",

    // chart: Daily Platform Data
    DAILY_RECORDS: "",

    // main chart option
    MAIN_CHART: "",

    // creator's video list
    VIDEO_LIST: "",
    VIDEO_LIST_TAB_ACTIVE: 0,

    // private tab Show and Hide Rendering When Creator Change
    PRIVATE_TAB_ACTIVE: 0,

    // platform URL list Show and Hide Rendering When Creator Change
    PLATFORM_URL_LIST_ACTIVE: 0,

    // Indicates if creator_id is selected when user coming from Data Dashboard page to Creator Tracking page
    IS_COME_FROM_DASHBOARD: false,

    // connect creator
    CONNECT_CR_ID: 0,
    CONNECT_CR_NAME: "",

    // achievenments history records
    ACHIEVEMENT_HISTORY: "",

    // Private V2 API
    PRIVATE: "",
});

export { creatorStore };