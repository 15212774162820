console.log(
  'currently on ' + process.env.NODE_ENV + ' as ' + window.location.host
);
// console.log(window.location.host)
if (window.location.host === 'localhost:3000') {
  global.constants = {
    // SERVER_PORT:'7983',
    DEV_URL: 'http://dev.adoba.studio:7983',

    SERVER_DOMAIN: 'http://dev.adoba.studio',
    SERVER_URL: 'http://dev.adoba.studio:7983',
    COOKIE_URL: 'http://47.104.178.110:8797',
    TOKEN_URL: 'http://47.104.178.110:8797',
    ADD_COOKIE_URL: 'http://dev.adoba.studio:8796',
    ADC_HOST: 'http://47.108.175.244'
  };
} else if (window.location.host === 'adoba.studio') {
  global.constants = {
    // SERVER_PORT:'7983',
    DEV_URL: 'https://api.adoba.studio',

    SERVER_DOMAIN: 'https://api.adoba.studio',
    SERVER_URL: 'https://api.adoba.studio',
    COOKIE_URL: 'http://47.104.178.110:8797',

    TOKEN_URL: 'http://47.104.178.110:8797',
    ADD_COOKIE_URL: 'http://dev.adoba.studio:8796',
    ADC_HOST: 'http://47.108.175.244'
  };
} else if (window.location.host === '47.104.210.115') {
  global.constants = {
    // SERVER_PORT:'7983',
    DEV_URL: 'http://dev.adoba.studio:7983',

    SERVER_DOMAIN: 'http://dev.adoba.studio',
    SERVER_URL: 'http://dev.adoba.studio:7983',
    COOKIE_URL: 'http://47.104.178.110:8797',
    TOKEN_URL: 'http://47.104.178.110:8797',
    ADD_COOKIE_URL: 'http://dev.adoba.studio:8796',
    ADC_HOST: 'http://47.108.175.244'
  };
} else {
  global.constants = {
    // SERVER_PORT:'7983',
    DEV_URL: 'https://api.adoba.studio',

    SERVER_DOMAIN: 'https://api.adoba.studio',
    SERVER_URL: 'https://api.adoba.studio',
    COOKIE_URL: 'http://47.104.178.110:8797',

    TOKEN_URL: 'http://47.104.178.110:8797',
    ADD_COOKIE_URL: 'http://dev.adoba.studio:8796',
    ADC_HOST: 'http://47.108.175.244'
  };
}
