import { observable } from 'mobx';
import "../config"
const apiStore = observable({
    API_REGISTER: global.constants.SERVER_URL + "/api/user/register",
    API_lOGIN: global.constants.SERVER_URL + "/api/user/login",
    API_REFTOKEN: global.constants.SERVER_URL + "/api/refresh_token",

    API_USER_UPDATE: global.constants.SERVER_URL + "/api/user/update",

    // account profile picture change 修改头像
    API_PROFILE_PICTURE_CHANGE: global.constants.SERVER_URL + "/api/user/avatar",

    // acount user info 用户信息
    API_USER_INFO: global.constants.SERVER_URL + "/api/user/info",

    // user lists 用户列表
    API_USER_LIST: global.constants.SERVER_URL + "/api/user/list",

    // the new user lists 用户列表
    API_USERS_LIST: global.constants.SERVER_URL + "/api/user",

    // set the team 设置团队
    API_SET_THE_TEAM: global.constants.SERVER_URL + "/api/user/set_team",

    // Dashboard API 看板API
    API_DASHBOARD: global.constants.SERVER_URL + "/api/mcn_dashboard",

    // Dashboard API 看板分页API
    API_DASHBOARD_LIST: global.constants.SERVER_URL + "/api/dashboard/list",

    // Dashboard Panel 看板合计API
    API_DASHBOARD_TOTAL: global.constants.SERVER_URL + "/api/dashboard/total",

    // Dashboard Sort 看板排序
    API_DASHBOARD_SORT: global.constants.SERVER_URL + "/api/dashboard/sort",

    // DATA DASHBOARD TEAMS AND FANS
    API_DASHBOARD_TEAM_FANS: global.constants.SERVER_URL + "/api/dashboard/fans?platform_id=",

    // Dashboard Panel 看板面板
    API_DASHBOARD_PANEL: global.constants.SERVER_URL + "/api/mcn_dashboard_panel",

    // Dashbaord Category Panel
    API_DASHBOARD_CATEGORY_PANEL: global.constants.SERVER_URL + "/api/dashboard/category/platform",

    // Dashboard Weekly Summary Chart
    API_DAHSBOARD_SUMMARY_CHART_WEEKLY: global.constants.SERVER_URL + "/api/dashboard/chart/weekly",

    // creator list panel data 达人列表面板数据
    API_CREATOR_LIST_PANEL: global.constants.SERVER_URL + "/api/creator/panel",

    // creator list 达人列表
    API_CREATOR_LIST: global.constants.SERVER_URL + "/api/creator/list",

    // creator search 搜索达人
    API_CREATOR_SEARCH: global.constants.SERVER_URL + "/api/creator/search",

    // update data
    API_UPDATE_DATA: global.constants.COOKIE_URL + "/creator/task",

    // creator status
    API_CREATOR_STATUS: global.constants.COOKIE_URL + "/all_platform/status/",

    // creator's monthly tracking data
    API_CREATOR_MONTHLY_DATA: global.constants.SERVER_URL + "/api/creator/chart/monthly?",

    // creator's daily tracking data
    API_CREATOR_DAILY_DATA: global.constants.SERVER_URL + "/api/creator/chart/daily?",

    // creator cookie 达人cookie
    API_CREATOR_COOKIE: global.constants.COOKIE_URL + "/all_platform/cookie/",

    // creator cookie 单个达人cookie
    API_CREATOR_COOKIE_ALONE: global.constants.COOKIE_URL + "/all_platform/cookie/",

    // creator token 达人token
    API_ALL_CREATOR_TOKEN: global.constants.TOKEN_URL + "/all_platform/token/",

    // creator douyin token 达人douyin token
    API_CREATOR_TOKEN: global.constants.TOKEN_URL + "/douyin/api/token/",

    // creator douyin crawler 达人douyin crawler
    API_CREATOR_CRAWLER: global.constants.TOKEN_URL + "/douyin/api/crawler/",

    // creator douyin code
    API_CREATOR_DOUYIN_CODE: global.constants.TOKEN_URL + "/douyin/code/",

    // creator douyin task
    API_CREATOR_DOUYIN_TASK: global.constants.TOKEN_URL + "/douyin/task/",

    // creator baidu code
    API_CREATOR_HAOKAN_CODE: global.constants.TOKEN_URL + "/baidu/code/",

    // creator baidu token
    API_CREATOR_HAOKAN_TOKEN: global.constants.TOKEN_URL + "/baidu/api/token/",

    // creator baidu crawler
    API_CREATOR_HAOKAN_CRAWLER: global.constants.TOKEN_URL + "/baidu/api/crawler/",

    // creator baidu task
    API_CREATOR_HAOKAN_TASK: global.constants.TOKEN_URL + "/baidu/task/",

    // creator bili code
    API_CREATOR_BILI_CODE: global.constants.TOKEN_URL + "/bili/code/",

    // creator bili token
    API_CREATOR_BILI_TOKEN: global.constants.TOKEN_URL + "/bili/api/token/",

    // creator bili crawler
    API_CREATOR_BILI_CRAWLER: global.constants.TOKEN_URL + "/bili/api/crawler/",

    // creator bili task
    API_CREATOR_BILI_TASK: global.constants.TOKEN_URL + "/bili/task/",

    // creator xigua code
    API_CREATOR_XIGUA_CODE: global.constants.TOKEN_URL + "/xigua/code/",

    // creator xigua token
    API_CREATOR_XIGUA_TOKEN: global.constants.TOKEN_URL + "/xigua/api/token/",

    // creator xigua crawler
    API_CREATOR_XIGUA_CRAWLER: global.constants.TOKEN_URL + "/xigua/api/crawler/",

    // creator xigua task
    API_CREATOR_XIGUA_TASK: global.constants.TOKEN_URL + "/xigua/task/",

    // creator weibo code
    API_CREATOR_WEIBO_CODE: global.constants.TOKEN_URL + "/weibo/code/",

    // creator weibo token
    API_CREATOR_WEIBO_TOKEN: global.constants.TOKEN_URL + "/weibo/api/token/",

    // creator weibo crawler
    API_CREATOR_WEIBO_CRAWLER: global.constants.TOKEN_URL + "/weibo/api/crawler/",

    // creator weibo task
    API_CREATOR_WEIBO_TASK: global.constants.TOKEN_URL + "/weibo/task/",

    // creator info 达人信息
    API_CREATOR_INFO: global.constants.SERVER_URL + "/api/creator/info",

    // creator detail 达人详情
    API_CREATOR_DETAIL: global.constants.SERVER_URL + "/api/creator/dashboard/detail/",

    // creator date
    API_CREATOR_DATE: global.constants.SERVER_URL + "/api/creator/dashboard/month/",

    // creator memo 达人备注
    API_CREATOR_MEMO: global.constants.SERVER_URL + "/api/creator/remark/list",

    // creator report 达人报表
    API_CREATOR_REPORT: global.constants.SERVER_URL + "/api/creator/report_month",

    // creator memo add 添加达人备注
    API_CREATOR_MEMO_ADD: global.constants.SERVER_URL + "/api/creator/remark/add",

    // add creator 添加达人
    API_ADD_CREATOR: global.constants.SERVER_URL + "/api/creator/add",

    // modify creator 修改达人
    API_MODIFY_CREATOR: global.constants.SERVER_URL + "/api/creator/update",

    // unregister creator 注销达人
    API_DELETE_CREATOR: global.constants.SERVER_URL + "/api/creator/delete",

    // modify baidu token 修改百度token
    API_MODIFY_BAIDU_TOKEN: global.constants.SERVER_URL + "/api/creator/modifiy_baidu_token",

    // search videos 查找视频
    API_VIDEO_SEARCH: global.constants.SERVER_URL + "/api/video/search",

    // data download 下载数据
    // API_WEEKLY_FANS: global.constants.SERVER_URL+"/api/data/weekly_fans",
    API_DASHBOARD_DOWNLOAD: global.constants.SERVER_URL + "/api/dashboard/download",

    // resize picture 调整图片大小
    API_RESIZE_JPG: global.constants.SERVER_URL + "/api/other/resize_jpg_picture",
    API_RESIZE_PNG: global.constants.SERVER_URL + "/api/other/resize_png_picture",

    // creator's fans achievements history record
    API_ACHIEVEMENT_HISTORY: global.constants.SERVER_URL + "/api/creator/",

    // creator video list 达人视频列表
    API_CREATOR_VIDEO_LIST: global.constants.SERVER_URL + "/api/video/list",

    // video detailData 视频详情
    API_VIDEO_DETAIL: global.constants.SERVER_URL + "/api/video/detailData",

    // video suggest words 视频建议
    API_VIDEO_SUGGEST_WORD: global.constants.SERVER_URL + "/api/video/suggest_words",

    // video dashboard 视频看板
    API_VIDEO_DASHBOARD: global.constants.SERVER_URL + "/api/video/dashboard",

    // token update 更新token
    API_BAIDU_TOKEN_CHECK: global.constants.SERVER_URL + "/api/platform_api/check/baidu", // haokan
    API_BILI_TOKEN_CHECK: global.constants.SERVER_URL + "/api/platform_api/check/bilibili", // bili

    API_BILIBILI_TOKEN_UPDATE: global.constants.SERVER_URL + "/api/platform_api/bili",// bili uk
    API_XIGUA_TOKEN_UPDATE: global.constants.SERVER_URL + "/api/platform_api/xigua", // xigua uk
    API_WEIBO_TOKEN_UPDATE: global.constants.SERVER_URL + "/api/platform_api/weibo", // weibo uk

    // video upload 上传视频
    API_VIDEO_UPLOAD: global.constants.SERVER_URL + "/api/video/upload",

    // video upload list 上传视频列表
    API_VIDEO_UPLOAD_LIST: global.constants.SERVER_URL + "/api/video/creator_upload_list",

    // save video to server 保存视频到服务器
    API_VIDEO_SAVE: global.constants.SERVER_URL + "/api/video/upload_file",

    // video upload record 上传视频历史记录
    API_VIDEO_UPLOAD_RECORD: global.constants.SERVER_URL + "/api/video/upload_record",

    // haokan upload category 上传好看种类
    // API_HAOKAN_CATEGORY: global.constants.SERVER_URL+"/api/upload_video/baidu_category",

    // haokan topic api 好看主题api
    API_HAOKAN_TOPIC: global.constants.SERVER_URL + "/api/upload_video/baidu_topic",

    // bilibili tags and event tags bilibili标签和事件标签
    API_BILI_EVENT: global.constants.SERVER_URL + "/api/upload_video/bilibili_event",

    // video tags and category 视频tags和种类
    API_VIDEO_CATEGORY: global.constants.SERVER_URL + "/api/video/category",
    API_VIDEO_TAG: global.constants.SERVER_URL + "/api/video/tags",

    // xigua topic api 西瓜主题api
    API_XIGUA_TOPIC: global.constants.SERVER_URL + "/api/upload_video/xigua_event",

    // ali sts 阿里cookie
    API_ALI_STS: global.constants.SERVER_URL + "/ali/sts",

    // 获取资源
    API_GET_RESOURCE: global.constants.SERVER_URL + "/api/resource",

    // 外部任务
    API_OUTSIDE_TASK: global.constants.COOKIE_URL + "/scheduler/api/task/",

    // 外部时间
    API_OUTSIDE_TIME: global.constants.COOKIE_URL + "/scheduler/api/time/",

    // 外部解析
    API_OUTSIDE_PARSE: global.constants.COOKIE_URL + "/scheduler/parse/",

    // 外部达人数据列表
    API_ONWER: global.constants.ADC_HOST + "/api/owner_list/",

    // 添加外部达人的数据
    API_ADD_CREATOR_DATA: global.constants.ADC_HOST + "/api/owner/",

    // 添加cookie
    API_ADD_COOKIE: global.constants.ADC_HOST + "/api/cookie/",

    // 外部视频数据
    API_CONNECT_VIDEO: global.constants.ADC_HOST + "/api/video/",

    // 外部任务数据
    API_CONNECT_TASK: global.constants.ADC_HOST + "/api/task/",

    // 外部时间列表
    API_CONNECT_TIME: global.constants.ADC_HOST + "/task/time",

    // 外部平台
    API_CONNECT_PLATFORM: global.constants.ADC_HOST + "/api/platform/",

    // cookie update 更新cookie
    API_COOKIE: global.constants.ADD_COOKIE_URL + "/api/cookie/",

    // 获取creator隐私信息
    API_DOCUMENT_INFO: global.constants.SERVER_URL + "/api/creator/privacy/",

    // 修改平台账号密码
    API_CHANGE_PWD: global.constants.SERVER_URL + "/api/creator/account/info",

    // 获取所有活跃平台
    API_ACTIVE_PLATFORM: global.constants.SERVER_URL + "/api/active/platform",

    // creator Favourite List on creator tracking page
    API_FAVOURITE_LIST: global.constants.SERVER_URL + "/api/user/favorites",

    // add and delte Favourite List on creator tracking page
    API_FAVOURITE_MODIFY: global.constants.SERVER_URL + "/api/user/favorites?creator_id=",

    // latest headline news notification api
    API_HEADLINE_NOTIFICATION: global.constants.SERVER_URL + "/api/headline-notifications?offset=0&limit=20",

    // Data Dashboard's Ranking Top 10 API
    API_DATA_DASHBOARD_MONTHLY_RANKING: global.constants.SERVER_URL + "/api/dashboard/ranking/month/",

    // Get Power BI accounts
    API_POWER_BI_ACCOUNT: global.constants.SERVER_URL + "/api/power-bi/accounts/self",

    // Monthly Letter's Event
    API_MONTHLY_LETTER_EVENT: global.constants.SERVER_URL + "/api/letter-events/monthly",

    // Privacy API Version 2
    API_PRIVACY_V2_API: global.constants.SERVER_URL + "/api/creator/v2/",

    // Passport and Contract Copy Download and Upload API
    API_PASSPORT_COPY_DOWNLOAD: global.constants.SERVER_URL + "/api/creator/v2/downloads",
    API_PASSPORT_COPY_UPLOADS: global.constants.SERVER_URL + "/api/creator/v2/uploads",

    // IP White List
    API_ADD_WHITELIST: global.constants.SERVER_URL + "/add/white_ip",
    KEY_ADD_WHITELIST: "?G)3WA?s0*6?wYnRUgwC",

    // 获取oss临时凭证 0.5h
    API_OSS_KEY_URL: global.constants.SERVER_URL + "/ali/sts",

    // 获取达人视频上传记录
    API_UPLOAD_LIST_URL: global.constants.SERVER_URL + "/api/video/upload_record/",

    // 保存达人视频上传记录
    API_UPLOAD_SAVE_URL: global.constants.SERVER_URL + "/api/video/upload_record",

    
});

export { apiStore };