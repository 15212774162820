import axios from 'axios';
import Cookie from 'js-cookie';
import useStore from '../useStore';
// import errorMessage from '../views/errors/errorMessage'
// import { useNavigate } from 'react-router-dom';

let instance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})

const { authStore, apiStore } = useStore()
// const navigate = useNavigate();

instance.interceptors.request.use(
    config => {
        const token = Cookie.get('token')
        if (token !== "") { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.token = token  //请求头加上token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

instance.interceptors.response.use(
    async response => {
        if (response.data.status === -101) {
            console.log('daozheerla')
            // alert('token 已到期，点击刷新')
            authStore.tokenExpired = false
            let refUrl = apiStore.API_REFTOKEN;
            let refreshToken = Cookie.get('refresh_token')
            let formData = new FormData()
            formData.append("refresh_token", refreshToken)

            if (!authStore.tokenExpired && refreshToken !== "") {
                await axios.put(refUrl, formData, {
                    headers: {
                        "Content-Type": "application/form-data; charset=UTF-8"
                    }
                }).then(res => {
                    console.log(res)
                    if (res.data.data === null) {
                        console.log('refresh_token data null')
                        // navigate('/login');
                        Cookie.set('token', "")
                        Cookie.set('refresh_token', "")
                        window.location.replace("/login");
                    } else {
                        Cookie.set('token', res.data.data.token)
                        Cookie.set('refresh_token', res.data.data.refresh_token)
                        authStore.API_KEY = res.data.data.token
                        console.log(window.location.href)
                        window.location.reload(window.location.href)
                        // location.reload()
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        }
        // else if (response.data.status !== 0) {
        // window.location.replace("/404");
        //     console.log(response.data.status)
        //     alert(errorMessage[response.data.status])
        // }
        else {
            authStore.tokenExpired = true
        }

        return response
    },
    err => {
        return Promise.reject(err)
    }
)

export default instance
