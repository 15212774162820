import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from './meta_loading.json';



const LoadingScreen = () => {
    
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loadingAnimation,
        rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={{justifyContent: 'center', alignContent: 'flex-start', textAlign: 'left', marginTop: '10%'}}>
            <Lottie options={defaultOptions}
                    width={'30%'} />
            <p style={{justifyContent: 'center', alignContent: 'center', textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#555'}}>Data Loading...</p>
        </div>
    )
}


export default LoadingScreen;