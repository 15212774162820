import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';
import LoginView from './views/auth/LoginView';
import LoadingScreen from '../src/utils/loading/loading';
import instance from './utils/axios';

import useStore from './useStore';

import './config';
import RegisterView from './views/auth/RegisterView';
import Cookie from 'js-cookie';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const App = () => {
  const routing = useRoutes(routes);
  const [superAuth, setSuperAuth] = useState(false);
  const classes = useStyles();
  // const [ip, setIP] = useState({ country_name: 'unknown', IPv4: 'unknown' }); // IP address

  const { apiStore, authStore } = useStore();
  const { i18n } = useTranslation();

  // Modal
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // IP WHITELIST Modal
  const [IPERROR, setIPERROR] = useState(false);
  const handleIPerror = () => {
    setIPERROR(false);
    _logout();
  };

  // logout
  async function _logout() {
    sessionStorage.email = '';
    sessionStorage.password = '';
    await Cookie.set('token', '');
    await Cookie.set('refresh_token', '');
    await window.location.replace('/');
  }

  useEffect(() => {
    console.log(
      '%cadoba studio 1.3.1',
      'color:#fa234c; font-size:33px; font-weight:bold;'
    );
    console.log(
      '%cPowered by',
      'color:#fa234c; font-size:18px; font-weight:bold;'
    );
    // console.log("%c ", "font-size: 1px; padding: 115px 385px; background-size: 570px 430px; background: no-repeat url(https://media.giphy.com/media/x7lkG968kdagw/giphy.gif);");
    console.log(
      '%c ',
      'font-size: 1px; padding: 115px 385px; background-size: 570px 430px; background: no-repeat url(https://media.giphy.com/media/3oz8xHWg39lGRtFvHO/giphy.gif);'
    );
    // console.log("%c ", "font-size: 1px; padding: 215px 385px; background-size: 770px 430px; background: no-repeat url(https://media.giphy.com/media/fmkYSBlJt3XjNF6p9c/giphy.gif);");
    console.log(
      `%c
 █████╗ ██████╗  ██████╗ ██████╗  █████╗     █████╗ ██╗
██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔══██╗   ██╔══██╗██║
███████║██║  ██║██║   ██║██████╔╝███████║   ███████║██║
██╔══██║██║  ██║██║   ██║██╔══██╗██╔══██║   ██╔══██║██║
██║  ██║██████╔╝╚██████╔╝██████╔╝██║  ██║██╗██║  ██║██║

███████╗███╗   ██╗ ██████╗ ██╗███╗   ██╗███████╗███████╗██████╗ 
██╔════╝████╗  ██║██╔════╝ ██║████╗  ██║██╔════╝██╔════╝██╔══██╗
█████╗  ██╔██╗ ██║██║  ███╗██║██╔██╗ ██║█████╗  █████╗  ██████╔╝
██╔══╝  ██║╚██╗██║██║   ██║██║██║╚██╗██║██╔══╝  ██╔══╝  ██╔══██╗
███████╗██║ ╚████║╚██████╔╝██║██║ ╚████║███████╗███████╗██║  ██║
╚══════╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝╚══════╝╚══════╝╚═╝  ╚═╝
`,
      'color: orange'
    );

    if (localStorage.getItem('@LANG') === 'kr') {
      i18n.changeLanguage('kr');
      authStore.language = 'kr';
    }
    if (localStorage.getItem('@LANG') === 'ch') {
      i18n.changeLanguage('ch');
      authStore.language = 'cn';
    }
    if (localStorage.getItem('@LANG') === 'en') {
      i18n.changeLanguage('en');
      authStore.language = 'en';
    }
    if (localStorage.getItem('@LANG') === 'jp') {
      i18n.changeLanguage('jp');
      authStore.language = 'en';
    }

    let userCookie = Cookie.get('token');
    if (userCookie === '' || userCookie === undefined) {
      setSuperAuth(false);
    } else {
      authStore.API_KEY = userCookie;

      // CHECK IF LOGED WHERE IP IS NOT REGISTERED
      let api_key = authStore.API_KEY;
      let url = apiStore.API_USER_INFO;
      let data = '';
      instance
        .get(url, data, {
          headers: {
            token: api_key,
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        })
        .then(res => {
          if (res.data.status === -1) {
            setIPERROR(true);
          } else {
            setSuperAuth(true);
          }
        });
    }
  }, []);

  // const showLoginPage = withRouter(({ match, location }) => {
  //   return routes[1].children[0].element;
  // })

  const BeforeLogin = () => {
    if (authStore.SIGN_UP === true) {
      return <LoginView />;
    } else {
      return <RegisterView />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">업데이트 패치 안내</h2>
            <p id="transition-modal-description">
              <br />
              adoba studio는 현재 주기적으로 업데이트 및 패치가 이루어 지고
              있습니다
              <br />
              하루에도 여러번 실시간 업데이트 되는 경우가 있으며,
              <br />
              사용자의 브라우저에 남아있는 캐시 때문에 업데이트가 바로 반영이 안
              될 경우가 있습니다.
              <br />
              <br />
              이런경우, Control + Shift + R 버튼을 눌러서 캐시 새로고침을 자주
              해주세요.
            </p>
          </div>
        </Fade>
      </Modal>

      {/* IP ERROR MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={IPERROR}
        onClose={handleIPerror}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={IPERROR}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">IP is NOT REGISTERED.</h2>
            <br />
            <p id="transition-modal-description">
              Please Submit Your IP address to IT Department <br /> IP未注册。
              请向开发部门提交IP地址。
              <br /> IP가 등록되지 않았습니다. IP 주소를 개발 부서에 제출해
              주세요.
            </p>
          </div>
        </Fade>
      </Modal>

      <GlobalStyles />

      {superAuth ? (
        <Suspense fallback={<LoadingScreen />}>{routing}</Suspense>
      ) : (
        <BeforeLogin />
      )}
    </ThemeProvider>
  );
};

export default App;
