import { observable } from 'mobx';

const test1 = observable({
    number: 1,
    increase(){
        this.number++;
    },
    decrease(){
        this.number--;
    }
});

export {test1};