import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Page from 'src/components/Page';
import adoba_logo from './adoba_logo.png';
import axios from 'axios';
import Lottie from 'react-lottie';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import useStore from '../../useStore';
import { useTranslation } from 'react-i18next';
import srcAnimation from 'src/icons/start.json';
import instance from 'src/utils/axios';
import Cookie from 'js-cookie';
import './log.css';
import 'src/config';
import { Translate } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
  },
  label: {
    textTransform: 'capitalize'
  }
})(Button);

const { authStore, apiStore } = useStore();

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ip, setIP] = useState({ country_name: 'unknown', IPv4: 'unknown' });

  // i18next
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  // Modal
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    window.location.replace('/');
  };
  const [start, setStart] = useState(false);

  const [serverErrorModal, setServerErrorModal] = useState(false);
  const serverErrorClose = () => {
    setServerErrorModal(false);
    window.location.replace('/');
  };

  const [IPERROR, setIPERROR] = useState(false);
  const handleIPerror = () => {
    setIPERROR(false);
    window.location.replace('/');
  };

  useEffect(() => {
    _detectVisitorsIP();
  }, []);

  async function _detectVisitorsIP() {
    await axios
      .get('https://geolocation-db.com/json/')
      .then(res => {
        setIP(res.data);
      })
      .catch(err => {
        setIP({ country_name: 'unknown', IPv4: 'unknown' });
      });
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: srcAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  function loginAdoba(value) {
    axios
      .post(apiStore.API_lOGIN, {
        account: value.email,
        password: value.password
      })
      .then(res => {
        // if (res.data.status === -1) {
        //   setIPERROR(true);
        // }
        if (res.data.status === 0) {
          sessionStorage.email = value.email;
          sessionStorage.password = value.password;
          Cookie.set('token', res.data.data.token);
          Cookie.set('refresh_token', res.data.data.refresh_token);
          authStore.API_KEY = res.data.data.token;
          window.location.replace('/');
        } else {
          setOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setServerErrorModal(true);
      });
  }

  return (
    <Page className={classes.root} title="Login">
      {/* {start ? ( */}
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box style={{ display: 'flex' }}>
            <div style={{ width: '50%', height: 150 }}>
              <img alt="" src={adoba_logo} width="100%"></img>
            </div>
            <div style={{ width: '50%', height: 150 }}>
              <Lottie
                options={defaultOptions}
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%'
                }}
              />
            </div>
          </Box>

          {/* Modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <h2 id="transition-modal-title">Login Failed</h2>
                <p id="transition-modal-description">
                  Please Check Email Or Password
                </p>
              </div>
            </Fade>
          </Modal>

          {/* IP ERROR MODAL */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={IPERROR}
            onClose={handleIPerror}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={IPERROR}>
              <div className={classes.paper}>
                <h2 id="transition-modal-title">IP is NOT REGISTERED.</h2>
                <p id="transition-modal-description">
                  Please Submit Your IP 🟢 [ {ip.IPv4} ] to IT Department
                </p>
              </div>
            </Fade>
          </Modal>

          {/* ServerErrorModal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={serverErrorModal}
            onClose={serverErrorClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={serverErrorModal}>
              <div className={classes.paper}>
                <h2 id="transition-modal-title">Server Error</h2>
                <p id="transition-modal-description">
                  <br />
                  Cannot Access To The Server
                </p>
              </div>
            </Fade>
          </Modal>

          <Formik
            initialValues={
              {
                // email: 'test@test.com',
                // password: '123'
              }
            }
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('please write email'),
              password: Yup.string()
                .max(255)
                .required('please write password')
            })}
            onSubmit={value => {
              loginAdoba(value);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    <p
                      style={{
                        color: '#ff3600',
                        fontSize: 33,
                        fontWeight: 'semi-bold'
                      }}
                    >
                      adoba studio
                    </p>
                    <p style={{ fontSize: 18, fontWeight: 'semi-bold' }}>
                      Cross Border Data Platform System
                    </p>
                  </Typography>
                  {/* <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      <span>{t("register_or_login")}</span>
                    </Typography> */}
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {/* <StyledButton
                        color="primary"
                        fullWidth
                        // startIcon={<FacebookIcon />}
                        // onClick={handleSubmit}
                        // onClick={() => {authStore.SIGN_UP = false; navigate('/register');}}
                        onClick={() => {alert('PLEASE REQUEST ADMIN FOR ISSUE YOUR ACCOUNT')}}
                        size="large"
                        variant="contained"
                      >
                        {t("create_account")}
                      </StyledButton> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      // startIcon={<GoogleIcon />}
                      // onClick={handleSubmit}
                      onClick={() => {
                        // alert('Version Info: 1.3.1');
                        window.open('http://v2.adoba.studio');
                      }}
                      size="large"
                      variant="contained"
                      style={{
                        height: 48,
                        padding: '0 30px'
                      }}
                    >
                      {/* {t("register_as_company")} */}
                      Go To Version 1.4.0
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={3} mb={1}>
                  <p style={{ fontSize: 16, textAlign: 'center' }}>
                    <span style={{ fontWeight: 'bold' }}>IP detected</span> 🟢{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {ip.IPv4} from {ip.country_name}
                    </span>
                  </p>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('email_id')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <StyledButton
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#fa243c', color: '#fff' }}
                  >
                    {t('login')}
                  </StyledButton>
                </Box>
                <Box color="textSecondary" variant="body1">
                  <span>
                    {/* {t("ifyou_dont_have_account")} */}
                    Forgot ID or password ?
                  </span>{' '}
                  <Box
                    style={{ color: '#ff3300', cursor: 'pointer' }}
                    onClick={() => {
                      alert('Please contact IT Department');
                    }}
                  >
                    <span>
                      {/* {t("create_account")} */}
                      Find Account
                    </span>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
          <Box
            style={{
              paddingTop: 50,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                i18n.changeLanguage('kr');
                localStorage.setItem('@LANG', 'kr');
                authStore.language = 'kr';
              }}
              style={{ fontSize: '13px' }}
            >
              한국어
            </Button>
            <Button
              onClick={() => {
                i18n.changeLanguage('ch');
                localStorage.setItem('@LANG', 'ch');
                authStore.language = 'cn';
              }}
              style={{ fontSize: '13px' }}
            >
              中文
            </Button>
            <Button
              onClick={() => {
                i18n.changeLanguage('en');
                localStorage.setItem('@LANG', 'en');
                authStore.language = 'en';
              }}
              style={{ fontSize: '13px' }}
            >
              English
            </Button>
            <Button
              onClick={() => {
                i18n.changeLanguage('jp');
                localStorage.setItem('@LANG', 'jp');
                authStore.language = 'en';
              }}
              style={{ fontSize: '13px' }}
            >
              日本語
            </Button>
          </Box>
        </Container>
      </Box>
      {/* 需求改动：关闭欢迎页动画 */}
      {/* ) : (
        <>
          <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
            <defs>
              <radialGradient
                id="Gradient1"
                cx="50%"
                cy="50%"
                fx="10%"
                fy="50%"
                r=".5"
              >
                <animate
                  attributeName="fx"
                  dur="34s"
                  values="0%;3%;0%"
                  repeatCount="indefinite"
                />
                <stop offset="0%" stopColor="#ff0" />
                <stop offset="100%" stopColor="#ff00" />
              </radialGradient>
              <radialGradient
                id="Gradient2"
                cx="50%"
                cy="50%"
                fx="10%"
                fy="50%"
                r=".5"
              >
                <animate
                  attributeName="fx"
                  dur="23.5s"
                  values="0%;3%;0%"
                  repeatCount="indefinite"
                />
                <stop offset="0%" stopColor="#0ff" />
                <stop offset="100%" stopColor="#0ff0" />
              </radialGradient>
              <radialGradient
                id="Gradient3"
                cx="50%"
                cy="50%"
                fx="50%"
                fy="50%"
                r=".5"
              >
                <animate
                  attributeName="fx"
                  dur="21.5s"
                  values="0%;3%;0%"
                  repeatCount="indefinite"
                />
                <stop offset="0%" stopColor="#f0f" />
                <stop offset="100%" stopColor="#f0f0" />
              </radialGradient>
            </defs>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient1)">
              <animate
                attributeName="x"
                dur="20s"
                values="25%;0%;25%"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                dur="21s"
                values="0%;25%;0%"
                repeatCount="indefinite"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="17s"
                repeatCount="indefinite"
              />
            </rect>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient2)">
              <animate
                attributeName="x"
                dur="23s"
                values="-25%;0%;-25%"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                dur="24s"
                values="0%;50%;0%"
                repeatCount="indefinite"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="18s"
                repeatCount="indefinite"
              />
            </rect>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient3)">
              <animate
                attributeName="x"
                dur="25s"
                values="0%;25%;0%"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                dur="26s"
                values="0%;25%;0%"
                repeatCount="indefinite"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="360 50 50"
                to="0 50 50"
                dur="19s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
          <div
            className={"child"}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              letterSpacing: '-5px',
              mixBlendMode: 'overlay',
              textShadow: '0 0 5px #fff',
              color: '#fff6'
            }}
          >
            <p style={{ fontSize: '77px', fontWeight: 900 }}>adoba studio</p>
            <p style={{ fontSize: '44px' }}>1 . 3 . 1</p>
            <Box
              style={{
                paddingTop: 50,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={() => {
                  setStart(true);
                }}
                style={{
                  textTransform: 'none',
                  fontSize: '33px',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
              >
                Welcome
              </Button>
            </Box>
          </div>
        </> */}
      {/* )} */}
    </Page>
  );
};

export default LoginView;
