import { observable } from 'mobx';

const TRANSLATE_KR_STORE = observable({

    HELLO: "안녕하세요 번역 테스트",

    // 로그인 페이지
    register_or_login: "가입하거나 로그인 하세요",
    register_as_creator: "크리에이터 가입",
    register_as_company: "법인사업자 가입",
    ifyou_have_account: "계정이 있다면 아래 email 아이디로 로그인 하세요",
    email_id: "이메일 아이디",
    password: "비밀번호",
    login: "로그인",
    ifyou_dont_have_account: "아도바 Account가 없다면",
    create_account: "계정 만들기",
    login_failed: "접속 실패",
    login_failed_sub: "아이디와 비밀번호가 없거나 틀립니다.",
    plase_write_email: "이메일을 입력하세요",
    please_write_password: "비밀번호를 입력하세요",
    
    // 레지스터 페이지
    email_is_not_right: '이메일이 올바르지 않습니다.',
    please_write_name: '이름을 입력해주세요',
    enter_the_register_code: '레지스터 코드를 입력하세요',
    must_agree: '꼭 동의하셔야 합니다.',
    register_code_is_wrong: '레지스터 코드가 틀립니다.',
    admin_register_done: '가입접수가 완료되었습니다. 권한 부여에는 시간이 걸릴 수 있습니다.',
    register_admin_account: '새로운 adoba company 계정 생성신청',
    register_admin_adoba_account: '임직원용 adoba account 계정을 신청합니다. 이메일 아이디로 가입하세요.',
    register_name: '이름',
    choose_your_team: '소속을 선택하세요',
    head_team: '운영팀',
    cp_1team: 'CP 1팀',
    cp_2team: 'CP 2팀',
    cp_3team: 'CP 3팀',
    contens_support: '콘텐츠지원팀',
    contact_team: '대외협력팀',
    business_team: '전략사업팀',
    developer_team: '개발팀',
    choose_adoba_or_adoba_ai_team: '아도바 혹은 아도바에이아이의 소속을 선택하세요',
    email: '이메일',
    contact_phone_number: '연락처 전화번호',
    register_code: '레지스터 코드',
    register_code_is_all_capital_letter: '* 레지스터 코드는 모두 대문자 입니다.',
    you_agree_this: '이용약관에 동의합니다',
    policy: '이용약관',
    sign_up: '가입하기',
    already_have_account: '이미 계정이 있으신가요?',

    // 메뉴 Item
    dashboard: '대시보드',
    data_charts_panel: 'Data Charts Panel (개발중)',
    creator: '크리에이터',
    video_dashboard: '비디오 대시보드+',
    platform_list: '플랫폼 List (개발중)',
    creator_compare: '크리에이터 Compare',
    video_compare: '비디오 Compare (개발중)',
    notification_dashboard: '알림 모음 (개발중)',
    platform_video_upload: '플랫폼 영상 Upload (개발중)',
    marketstore_management: 'MarketStore 관리 (예정)',
    adoba_labs: '아도바 Labs',
    account_setting: '계정 설정',
    adoba_people: '아도바 임직원',
    

});

export {TRANSLATE_KR_STORE};